// pages/about.js
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer"
const AboutUs = () => {
  return (
    <div className="min-h-screen">
      <Header/>
      <div className="max-w-7xl mx-auto px-6 py-12">
        <h1 className="text-4xl font-bold text-center text-gray-950 mb-6">
          About Us
        </h1>
        <p className="text-lg text-gray-700 text-center mb-8">
          Welcome to <strong>Your App Name</strong>, where we strive to make
          healthcare simple, accessible, and effective. Our platform is
          designed to provide patients with easy access to experienced doctors
          across various specialties, ensuring top-notch medical care anytime,
          anywhere.
        </p>

        {/* Mission Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-950 mb-4">
            Our Mission
          </h2>
          <p className="text-gray-700 text-lg leading-relaxed">
            Our mission is to redefine the way healthcare is delivered. By
            leveraging technology, we connect patients and healthcare providers
            to create a seamless and stress-free experience. Whether it’s
            scheduling an appointment, consulting with a doctor, or managing
            medical records, our goal is to empower patients and simplify
            healthcare processes for all.
          </p>
          <p className="text-gray-700 text-lg leading-relaxed mt-4">
            We believe that everyone deserves access to quality healthcare,
            regardless of location or time. Our platform is built on trust,
            reliability, and a commitment to improving lives.
          </p>
        </div>

        {/* Why Choose Us Section */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-950 text-center mb-4">
            Why Choose Us?
          </h2>
          <ul className="list-disc list-inside text-gray-700 text-lg space-y-3 max-w-4xl mx-auto">
            <li>
              <strong>Convenience:</strong> Book appointments anytime and from
              anywhere with just a few clicks.
            </li>
            <li>
              <strong>Comprehensive Care:</strong> Access a wide range of
              specialists and healthcare services in one place.
            </li>
            <li>
              <strong>Secure Platform:</strong> Your data is protected with
              advanced encryption and confidentiality.
            </li>
            <li>
              <strong>Expert Guidance:</strong> Consult with highly qualified
              doctors dedicated to your well-being.
            </li>
            <li>
              <strong>24/7 Support:</strong> Reach out for emergency assistance
              or consultations whenever you need them.
            </li>
          </ul>
        </div>

        {/* Call-to-Action Section */}
        <div className="mt-12 text-center">
          <h3 className="text-xl font-semibold text-gray-950 mb-2">
            Join Us Today!
          </h3>
          <p className="text-gray-700 text-lg">
            At <strong>Your App Name</strong>, we’re more than just a platform
            — we’re your healthcare partner. Let us help you take control of
            your health journey with ease, confidence, and support at every
            step.
          </p>
          <p className="text-gray-700 text-lg mt-4">
            Together, we can build a healthier, happier future for everyone.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default AboutUs;
