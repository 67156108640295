import React from "react";
import cardiologist from "../../assets/team/cardiologist.jpg"
import dermatilogist from "../../assets/team/dermatilogist.jpg"
import neurologist from "../../assets/team/neurologist.jpg"
import pediatrician from "../../assets/team/pediatrician.jpg"
import ambulanceDriver from "../../assets/team/ambulanceDriver.jpg"
import emt from "../../assets/team/emt.jpg"
import labTechnician from "../../assets/team/labTechnician.jpg"
import pharmacyAssistant from "../../assets/team/pharmacistAssistant.jpg"
import pharmacist from "../../assets/team/pharmacist.jpg"
import pathologist from "../../assets/team/pathologist.jpg"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer"
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const doctors = [
  // Doctors
  {
    id: 1,
    name: "Dr. Sarah Ali",
    specialty: "Cardiologist",
    education: "MBBS, MD (Cardiology)",
    description:
      "Dr. Sarah has over 10 years of experience in treating heart diseases. She specializes in advanced cardiac procedures and patient care.",
    image: cardiologist,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
  {
    id: 2,
    name: "Dr. Ahmed Khan",
    specialty: "Pediatrician",
    education: "MBBS, MD (Pediatrics)",
    description:
      "Dr. Ahmed is a compassionate pediatrician dedicated to the health and well-being of children. He excels in developmental care and immunization.",
    image: pediatrician,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
  {
    id: 3,
    name: "Mr. Faisal Javed",
    specialty: "Pharmacy Assistant",
    education: "Diploma in Pharmacy",
    description:
      "Mr. Faisal assists in inventory management and ensures the availability of medicines in the pharmacy.",
    image: pharmacyAssistant,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },

  {
    id: 4,
    name: "Dr. Maria Noor",
    specialty: "Dermatologist",
    education: "MBBS, MD (Dermatology)",
    description:
      "Dr. Maria is an expert in treating skin conditions and cosmetic dermatology. She focuses on providing personalized skin care solutions.",
    image: dermatilogist,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
  {
    id: 5,
    name: "Ms. Ayesha Malik",
    specialty: "Lab Technician",
    education: "BSc Medical Technology",
    description:
      "Ms. Ayesha has expertise in running diagnostic tests with precision and maintaining laboratory equipment.",
    image: labTechnician,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
  {
    id: 6,
    name: "Dr. Omar Siddiqui",
    specialty: "Neurologist",
    education: "MBBS, MD (Neurology)",
    description:
      "Dr. Omar specializes in treating neurological disorders such as migraines, epilepsy, and strokes with innovative techniques.",
    image: neurologist,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
  {
    id: 7,
    name: "Mr. Ali Raza",
    specialty: "Ambulance Driver",
    education: "Professional Driver Training",
    description:
      "Mr. Ali is an experienced ambulance driver ensuring safe and prompt transportation of patients.",
    image: ambulanceDriver,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },

  // Laboratory Staff
  
  {
    id: 8,
    name: "Mr. Bilal Hussain",
    specialty: "Pathologist",
    education: "MBBS, MD (Pathology)",
    description:
      "Mr. Bilal specializes in analyzing lab results to assist doctors in accurate diagnosis and treatment plans.",
    image: pathologist,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },

  // Pharmacy Staff
  {
    id: 9,
    name: "Ms. Sana Qureshi",
    specialty: "Pharmacist",
    education: "PharmD",
    description:
      "Ms. Sana ensures proper dispensing of medications and educates patients about their usage and side effects.",
    image: pharmacist,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
 
  // Ambulance Crew
 
  {
    id: 10,
    name: "Ms. Zainab Karim",
    specialty: "Emergency Medical Technician",
    education: "Certified EMT",
    description:
      "Ms. Zainab provides pre-hospital care to patients in emergency situations during ambulance transport.",
    image: emt,
    social: {
      facebook: "https://facebook.com",
      twitter: "https://twitter.com",
      linkedin: "https://linkedin.com",
    },
  },
];


function DoctorTeamPage() {
  return (
    <div>
        <Header/>
    <div className="min-h-screen py-12">
      <div className="container mx-auto px-4">
        {/* Title Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800">Our Team</h1>
          <p className="text-gray-600 mt-4">
            Meet our team of highly qualified and dedicated medical professionals.
          </p>
        </div>

        {/* Doctor Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {doctors.map((doctor) => (
            <div
              key={doctor.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
            >
              {/* Image */}
              <img
                src={doctor.image}
                alt={doctor.name}
                className="w-full h-48 object-cover"
              />
              {/* Info Section */}
              <div className="p-4 text-center">
                <h3 className="text-xl font-bold text-gray-800">{doctor.name}</h3>
                <p className="text-gray-600 mt-2">{doctor.specialty}</p>
                <p className="text-gray-500 text-sm">{doctor.education}</p>
                <p className="text-gray-700 mt-4">{doctor.description}</p>

                {/* Social Media Links */}
                <div className="flex justify-center space-x-4 mt-4">
                  <a
                    href={doctor.social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-green-600 text-gray-500"
                  >
                    <FaFacebook/>
                  </a>
                  <a
                    href={doctor.social.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-green-600 text-gray-500"
                  >
                   <FaSquareXTwitter/>
                  </a>
                  <a
                    href={doctor.social.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-green-600 text-gray-500"
                  >
                    <FaLinkedin/>
                    
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default DoctorTeamPage;
