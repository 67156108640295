import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MapPage = () => {
  let { mapId } = useParams();

  const [location, setLocation] = useState();
  const [apiResponse, setApiResponse] = useState(false);

  const LIVE_URL = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${LIVE_URL}/api/emergency/getMessage/${mapId}`
        );
        setLocation(response?.data?.data);
        setApiResponse(true);
      } catch (err) {
        setApiResponse(true);
      }
    };
    fetchData();
  }, [mapId, LIVE_URL]);

  return (
    <div style={{ width: "100%", height: "99vh" }}>
      {location ? (
        <>
          <iframe
            src={`https://www.google.com/maps?q=${location.lat},${location.long}&hl=es;&output=embed`}
            title="Driver Location"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          ></iframe>
        </>
      ) : (
        <>
          {apiResponse && (
            <>
              <h1>No Such Map Found</h1>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MapPage;
