import React from 'react'
import doctor from "../../assets/doctor.png";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";



function Services() {
  return (
    <div>
 
<Header/>

 <section className="py-16">
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        {/* Left Services Section */}
        <div className="w-full md:w-1/3 px-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Our Services</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Online Consultations</h3>
              <p className="text-gray-600">Connect with experienced doctors via video or chat anytime, anywhere.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Lab Tests Booking</h3>
              <p className="text-gray-600">Easily book diagnostic tests and get accurate results delivered online.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Health Check-ups</h3>
              <p className="text-gray-600">Comprehensive health check-up packages tailored to your needs.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Specialist Appointments</h3>
              <p className="text-gray-600">Schedule appointments with top specialists for personalized care.</p>
            </div>
          </div>
        </div>

        {/* Center Doctor Image */}
        <div className="w-full md:w-1/3 px-4 flex justify-center items-center">
          <img
            src={doctor}
            alt="Doctor"
            className=""
          />
        </div>

        {/* Right Services Section */}
        <div className="w-full md:w-1/3 px-4">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Why Choose Us</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-gray-700">24/7 Medical Support</h3>
              <p className="text-gray-600">Our team is always ready to assist you at any time of the day.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Affordable Treatments</h3>
              <p className="text-gray-600">We provide cost-effective healthcare solutions without compromising quality.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Experienced Doctors</h3>
              <p className="text-gray-600">Our medical team includes highly qualified and experienced professionals.</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-700">Personalized Care</h3>
              <p className="text-gray-600">Receive healthcare tailored to your unique requirements and conditions.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
<Footer/>

    </div>
  )
}

export default Services
