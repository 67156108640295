import React from 'react'
import logo from "../../assets/logo.png";
import { CiLocationOn } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { CiMail } from "react-icons/ci";


function Footer() {
  return (
    
  <footer className="text-gray-600 text-sm  body-font border-t border-gray-100 shadow-lg ">
  <div className="container px-5 py-12 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <img src={logo} alt="logo" />
      </a>
      <ul className='mt-2 text-sm'>
    <li className="mb-2 flex gap-4 items-center">
     
      <span><CiPhone className='h-6 w-6'/></span> +1 (123) 456-7890
    </li>
    <li className="mb-2 flex gap-4 items-center">
      <span><CiMail className='h-6 w-6'/></span>contact@doctorapp.com
    </li>
    <li className="mb-2 flex  gap-4 items-center">
   
    <span><CiLocationOn className='h-6 w-6'/></span> 123 Health Street, Wellness City, 56789.
    </li>
  </ul>
    
    </div>
    <div className="flex-grow justify-center flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">NAVIGATION</h2>
        <nav className="list-none space-y-1 mb-10">
          <li>
            <a href='/' className="text-gray-600 hover:text-gray-800 cursor-pointer">Home</a>
          </li>
          <li>
            <a href='/service' className="text-gray-600 hover:text-gray-800 cursor-pointer">Service</a>
          </li>
          <li>
            <a href='/about' className="text-gray-600 hover:text-gray-800 cursor-pointer">About</a>
          </li>
        
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">EXPLORE</h2>
        <nav className="list-none space-y-1  mb-10">
          <li>
            <a href='/team' className="text-gray-600 hover:text-gray-800 cursor-pointer">Team</a>
          </li>
          <li>
            <a  href='/contact-us'className="text-gray-600 hover:text-gray-800 cursor-pointer">Contact Us</a>
          </li>
         
        
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">FEATURES</h2>
        <nav className="list-none space-y-1  mb-10">
          <li>
            <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Patient/Doctor Portal</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Laboratory Portal</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Pharmacy Portal</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800 cursor-pointer">Ambulance Portal</a>
          </li>
        </nav>
      </div>
      {/* <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
        <nav className="list-none mb-10">
          <li>
            <a className="text-gray-600 hover:text-gray-800">First Link</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800">Second Link</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800">Third Link</a>
          </li>
          <li>
            <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
          </li>
        </nav>
      </div> */}
    </div>
  </div>
  <div className="bg-gray-100">
    <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p className="text-gray-500 text-sm text-center sm:text-left">© 2020 medi-ca —
        <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">@knyttneve</a>
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a className="text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
            <rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0} className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
            <circle cx={4} cy={4} r={2} stroke="none" />
          </svg>
        </a>
      </span>
    </div>
  </div>
</footer>

  
  )
}

export default Footer
