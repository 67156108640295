import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import AudioPage from "./pages/audio/AudioPage";
import MapPage from "./pages/map/MapPage";
import NotFoundPage from "./pages/notFound/NotFoundPage";
import ComingSoonPage from "./pages/comingSoon/ComingSoon";
import Services from "./components/services/Services";
import Team from "./components/team/Team";
import ContactUs from "./components/contact-us/ContactUs";
import AboutUs from "./components/about-us/AboutUs";




function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/service" element={<Services />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/team" element={<Team />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/audio/:audioId" element={<AudioPage />} />
      <Route path="/map/:mapId" element={<MapPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
