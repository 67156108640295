import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const AudioPage = () => {
  let { audioId } = useParams();

  const [voiceAudio, setVoiceAudio] = useState();
  const [apiResponse, setApiResponse] = useState(false);

  const LIVE_URL = process.env.REACT_APP_LIVESERVERURL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${LIVE_URL}/api/emergency/getMessage/${audioId}`
        );
        setVoiceAudio(response?.data?.data);
        setApiResponse(true);
      } catch (err) {
        setApiResponse(true);
      }
    };
    fetchData();
  }, [audioId, LIVE_URL]);

  return (
    <div className="containerr">
      <div className="container-autio">
        {voiceAudio ? (
          <>
            <audio controls>
              <source src={voiceAudio.mediaLink} type="audio/mpeg" />
              <track
                src="captions.vtt"
                kind="captions"
                label="English"
                srcLang="en"
              />
              Your browser does not support the audio tag.
            </audio>
            <p className="container-location">
              <Link to={`/map/${audioId}`}>Show Map Location</Link>
            </p>
          </>
        ) : (
          <>
            {apiResponse && (
              <>
                <h1>No Such Audio File Found</h1>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AudioPage;
