import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer"
// import { FaSquareInstagram } from "react-icons/fa6";


function ContactUs() {
  return (
   
 <div>
    <Header/>
    <div className="min-h-screen py-12">
      <div className="container mx-auto px-4">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800">Contact Us</h1>
          <p className="text-gray-600 mt-4">
            We’re here to help. Reach out to us with any questions or concerns.
          </p>
        </div>

        {/* Main Section */}
        <div className="flex flex-wrap lg:flex-nowrap gap-8">
          {/* Send Us a Message (Left Block) */}
          <div className="w-full lg:w-1/2 bg-white p-6 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Send Us a Message
            </h2>
            <form>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-medium mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Enter your name"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-medium mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-medium mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  rows="5"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                  placeholder="Write your message here..."
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-green-600 text-white py-2 px-4 rounded-md  transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Get in Touch (Right Block) */}
          <div className="w-full lg:w-1/2 bg-green-600 text-white p-6 shadow-lg rounded-lg">
  <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
  <p className="mb-4"> If you have any questions or need assistance, don't hesitate to reach out. We're here to help and ensure you have all the information you need. Use the contact details below to get in touch with us at your convenience. </p>
  <ul className='mt-4'>
    <li className="mb-4 flex gap-4 items-center">
     
      <span><CiPhone className='h-8 w-8'/></span> +1 (123) 456-7890
    </li>
    <li className="mb-4 flex gap-4 items-center">
      <span><CiMail className='h-8 w-8'/></span>contact@doctorapp.com
    </li>
    <li className="mb-4 flex  gap-4 items-center">
   
    <span><CiLocationOn className='h-8 w-8'/></span> 123 Health Street, Wellness City, 56789.
    A prime location offering wellness facilities and serene surroundings.
    </li>
  </ul>
  <div className='flex flex-col justify-center items-center space-y-4 mt-6'>
  <h3 className='text-center text-3xl '> <strong>Follow</strong> us on <strong>Social</strong></h3>


  <div className="flex flex-row gap-5">
    <a
      href="https://facebook.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white hover:text-gray-300"
    >
     <span><FaFacebook className='w-9 h-9'/></span>
    </a>
    <a
      href="https://linkedin.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white hover:text-gray-300"
    >
     <span><FaYoutube className='w-9 h-9'/></span>
     
    </a>
    <a
      href="https://twitter.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white hover:text-gray-300"
    >
     <span><FaSquareXTwitter className='w-9 h-9'/></span>
 
    </a>
    <a
      href="https://linkedin.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-white hover:text-gray-300"
    >
     <span><FaLinkedin className='w-9 h-9'/></span>
     
    </a>
   
  </div>
  </div>
</div>

        </div>

        {/* Map Section */}
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
            Our Location
          </h2>
          <div className="w-full h-64 bg-gray-300">
            <iframe
              className="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093746!2d144.95373531531644!3d-37.81627927975168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5777c0cbb9c0d91!2s123%20Health%20Street%2C%20Wellness%20City!5e0!3m2!1sen!2sus!4v1637689653936!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
              title="Clinic Location"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
 </div>

  );
}



export default ContactUs
