import React from "react";

const NoFoundPage = () => {
  return (
    <div className="containerr">
      <h1 className="container-autio">Page Not Found</h1>
    </div>
  );
};

export default NoFoundPage;
